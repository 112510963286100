import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Menu from '../../components/navigation';
import ProjectsText from '../../components/projects/text';
import ProjecsGallery from '../../components/projects/gallery';
import ProjectItem from '../../components/projects/item';
import Image from '../../components/projects/image';

import logo from '../../images/projects/pflegebienen/logo.svg';
import auto from '../../images/projects/pflegebienen/auto.svg';
import flug from '../../images/projects/pflegebienen/flug.svg';
import flug2 from '../../images/projects/pflegebienen/bienen-flug.svg';

import img1 from '../../images/projects/pflegebienen/img1.jpg';
import img12x from '../../images/projects/pflegebienen/img1@2x.jpg';
import img2 from '../../images/projects/pflegebienen/img2.jpg';
import img22x from '../../images/projects/pflegebienen/img2@2x.jpg';

import insta1 from '../../images/projects/pflegebienen/insta1.jpg';
import insta12x from '../../images/projects/pflegebienen/insta1@2x.jpg';
import insta2 from '../../images/projects/pflegebienen/insta2.jpg';
import insta22x from '../../images/projects/pflegebienen/insta2@2x.jpg';
import insta3 from '../../images/projects/pflegebienen/insta3.jpg';
import insta32x from '../../images/projects/pflegebienen/insta3@2x.jpg';
import insta4 from '../../images/projects/pflegebienen/insta4.jpg';
import insta42x from '../../images/projects/pflegebienen/insta4@2x.jpg';
import insta5 from '../../images/projects/pflegebienen/insta5.jpg';
import insta52x from '../../images/projects/pflegebienen/insta5@2x.jpg';
import insta6 from '../../images/projects/pflegebienen/insta6.jpg';
import insta62x from '../../images/projects/pflegebienen/insta6@2x.jpg';

const title = 'Corporate Design für den Pflegedienst Die Pflegebienen';
const text =
	'Für die Gründung eines neuen Pflegedienstes wurde in Zusammenarbeit mit der „Gral Unternehmensberatung“ ein innovativer Name ins Leben gerufen: „Die Pflegebienen“. Er steht bildhaft für Zuverlässigkeit, Fleiß und Freude an der täglichen Arbeit. Ebenso besticht auch die grafische Umsetzung – im wahrsten Wortsinn – durch einen frischen und fröhlichen Anstrich. Die sympathischen Bienen fliegen auf sämtlichen Werbemitteln, darunter auch auf Autos und der Webseite des Pflegedienstes.';

/**
 * Page
 */
const Page = () => {
	return (
		<Layout>
			<SEO title="" />
			<Menu selected="projekte" />
			<ProjectsText text={text} title={title} />
			<div className="page-width align-center"></div>
			<ProjecsGallery>
				<ProjectItem>
					<img src={logo} alt="" />
				</ProjectItem>
				<ProjectItem>
					<img src={auto} alt="" />
				</ProjectItem>
				<ProjectItem>
					<Image src={img1} src2={img12x} />
				</ProjectItem>
				<ProjectItem>
					<img src={flug} alt="" className="pb-flug" />
				</ProjectItem>
				<ProjectItem>
					<Image src={insta1} src2={insta12x} />
					<Image src={insta2} src2={insta22x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={insta3} src2={insta32x} />
					<Image src={insta4} src2={insta42x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={insta5} src2={insta52x} />
					<Image src={insta6} src2={insta62x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img2} src2={img22x} />
				</ProjectItem>
				<ProjectItem>
					<img src={flug2} alt="" />
				</ProjectItem>
			</ProjecsGallery>
		</Layout>
	);
};

export default Page;
